import React from 'react'
import { Segment } from 'semantic-ui-react'
import "../styles/components/CustomiseCardDetails.scss"
import SelectClub from '../containers/SelectClub'
import SelectCountry from '../containers/SelectCountry'

const CustomiseCardDetails = () => {
    return (
        <Segment vertical>
            <div className='stat-dropdown-wrapper'>
                <SelectClub></SelectClub>
                <SelectCountry></SelectCountry>
            </div>
        </Segment>
    )
}

export default CustomiseCardDetails