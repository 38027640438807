import React, { useState, useCallback } from 'react'
import "../styles/components/EditableImage.scss"
import Cropper from 'react-easy-crop'
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import { Button, Segment, Icon, Popup } from 'semantic-ui-react'
import 'rc-slider/assets/index.css';
import PlaceholderPerson from "../assets/landing/step-4-profile.svg";

import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"
import getCroppedImg from "../actions/cropImage.js"
import UploadImage from './UploadImage';

const { Handle } = Slider;

const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
        <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={value}
            visible={dragging}
            placement="top"
            key={index}
        >
            <Handle value={value} {...restProps} />
        </Tooltip>
    );
};


const EditableImage = observer((props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1);
    const [toolsEnabled, enableTools] = useState(false);
    // const [flip, setFlip] = useState([false, true]);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                store.uploadedPicture ? store.uploadedPicture : PlaceholderPerson,
                croppedAreaPixels,
                rotation,
                // { horizontal: flip[0], vertical: flip[1] }
            )
            store.croppedPicture = croppedImage;
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation]);

    const rotateLeft = () => {
        if (rotation <= 0) {
            setRotation(rotation + 90)
        } else {
            setRotation(rotation - 90)
        }
    }

    const rotateRight = () => {
        if (rotation >= 360) {
            setRotation(rotation - 90)
        } else {
            setRotation(rotation + 90)
        }
    }
    console.log(toolsEnabled);

    //This might be a bit dodgy. 
    //We'll need to change the transform scaleX(-1) for the preview and also set a bool for flip horizontal/vertical on getCroppedImg().
    // const flipMe = () => {
    //     setRotation(rotation-90)
    // }

    return (
        <Segment className='image-editor-wrapper'>
            <Segment  className='image-uploader' >
                <Popup className='edit-popup' 
                    content='We recommend uploading a transparent .png file of resolution of at least 800x800px (after cropping). Don’t have one? Step 2 unlocks after uploading a photo and our server process your image for you.
                    You can pan by clicking and dragging on the image, you can zoom in or rotate with the sliders in step 2, or rotate clockwise and anti-clockwise 90º at a time.' 
                    trigger={<Icon name='question circle outline'></Icon>} />
                <span className='step-text'>1</span>
            </Segment>
            <Segment className='image-editor' disabled>

                <span className='step-text'>2</span>
                <div className="crop-container">
                    {toolsEnabled ?
                        <Cropper
                            image={store.uploadedPicture ? store.uploadedPicture : PlaceholderPerson}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                        :
                        <div>

                            <UploadImage enableTools={enableTools}></UploadImage>
                        </div>
                    }
                </div>

            </Segment>

            <div className="controls">

                <span>Zoom</span>
                <Slider value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    defaultValue={1}
                    handle={handle}
                    onChange={(e) => setZoom(e)}
                    disabled={!toolsEnabled}
                />

                <span>Rotation</span>
                <Slider value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    defaultValue={1}
                    handle={handle}
                    onChange={(e) => setRotation(e)}
                    disabled={!toolsEnabled}
                />

                <div className='button-wrapper'>
                    <Button className="cancel-button"
                        onClick={() => rotateLeft()}
                        disabled={!toolsEnabled}>
                        Rotate Left
                    </Button>
                    <Button className="cancel-button"
                        onClick={() => rotateRight()}
                        disabled={!toolsEnabled}>
                        Rotate Right
                        </Button>
                    <Button className="submit-button"
                        onClick={() => showCroppedImage()}
                        disabled={!toolsEnabled}>
                        Finished
                        </Button>
                </div>


            </div>
        </Segment>
    )
})

export default EditableImage

