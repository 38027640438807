import _ from 'lodash'
import React, { useEffect } from 'react'
import { Table, Icon, Popup, Button } from 'semantic-ui-react'
import "../styles/components/SelectCountry.scss"
import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"
import SearchBar from "../components/SearchBar"
import Flag from 'react-world-flags'

const SelectCountry = observer(() => {
    useEffect(() => {
        //init data
        //resets the list when coming back to this screen
        store.filteredCountryList = store.countryList;
    }, []);

    var newArr = _.orderBy(store.filteredCountryList, 'title', 'asc')

    const setSelection = (country) => {
        store.selectedCountry = country.key;
    }

    const countrySelection = (
        newArr.map((_country, index) =>
            <Table.Row key={index}
                className={`select-country-row ${store.selectedCountry.key === _country.key ? 'selected' : ''}`}
                onClick={() => setSelection(_country)}>

                <Table.Cell colSpan='1' className='country-icon-column'>
                    <Flag code={_country.flagIcon} height={32} />
                </Table.Cell>
                <Table.Cell colSpan='3'>{_country.title}</Table.Cell>
            </Table.Row>
        )
    );

    const setResults = (results) => {
        store.filteredCountryList = results;
    }
    return (
        <div className='select-country-tool'>
            <Table className='select-country-table' unstackable striped celled textAlign="left">
                <Table.Header className="select-country-table-header" fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='3'>Select Country</Table.HeaderCell>
                        <Popup className='upload-popup' position='top right' wide
                            trigger={<Table.HeaderCell colSpan='1'><Icon name='question'></Icon></Table.HeaderCell>} on='click'>
                            <h4>Looking for something else?</h4>
                            <Button className='upload-photo'>upload photo</Button>
                        </Popup>
                    </Table.Row>
                    <Table.Row className='search-row'>
                        <Table.HeaderCell colSpan='4'>
                            <SearchBar dataToSearch={store.countryList} setResults={setResults}></SearchBar>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {countrySelection}
                </Table.Body>

            </Table>
        </div>
    )
})

export default SelectCountry