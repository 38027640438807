import React from 'react';  
import { Route } from 'react-router-dom';  
import PageFooter from "../PageFooter";


const LandingLayout = ({children, ...rest}) => {  
  return (  
    <>
        {children}
        <PageFooter></PageFooter>
    </> 
  )  
}  
  
const LandingLayoutRoute = ({component: Component, ...rest}) => {  
  return (  
    <Route {...rest} render={matchProps => (  
      <LandingLayout>  
          <Component {...matchProps} />  
      </LandingLayout>  
    )} />  
  )  
};  
  
export default LandingLayoutRoute; 