import React, { useRef } from 'react'
import { observer } from "mobx-react"
import "../styles/components/UploadImage.scss"
import store from '../actions/CreateCardStore.js';


function readFile(file) {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const UploadImage = observer((props) => {
    const fileInput = useRef(null);
    const { enableTools } = props;

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const imageDataUrl = await readFile(e.target.files[0])
            store.uploadedPicture = imageDataUrl;
            enableTools(true);
        }
    }


    return (
        <>
            <input ref={fileInput} className="uploadButton" type="file" onChange={e => onFileChange(e)} />
        </>
    )
})

export default UploadImage
