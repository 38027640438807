import _ from 'lodash'
import React, { useState } from 'react'
import { observer } from "mobx-react"
import { Search } from 'semantic-ui-react'
import "../styles/components/SearchBar.scss"


const SearchBar = observer((props) => {
    const { dataToSearch, setResults } = props;

    const [inputValue, setValue] = useState("");
    const [isLoading, setLoading] = useState(false);
    // const [results, setResults] = useState([]);
    // const [title, setTitle] = useState("");


    // const handleResultSelect = (e, { result }) => setTitle(result.title)

    const handleSearchChange = (e, { value }) => {
        setLoading(true);
        setValue(value);
        setTimeout(() => {
            if (value.length < 1)
                return setInitialState();

            const re = new RegExp(_.escapeRegExp(inputValue), 'i')
            const isMatch = (result) => re.test(result.title)

            setLoading(false);
            const filteredResults = _.filter(dataToSearch, isMatch);
            setResults(filteredResults);
        }, 300)
    }

    const setInitialState = () => {
        setLoading(false);
        setResults(dataToSearch)
    }

    return (
        <Search
            className="search-bar"
            loading={isLoading}
            // onResultSelect={handleResultSelect}
            onSearchChange={_.debounce(handleSearchChange, 500, {
                leading: true,
            })}
            // results={results}
            value={inputValue}
            {...props}
            size="mini"
            open={false}
            placeholder="or filter/search.."
        />
    )
})

export default SearchBar