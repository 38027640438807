import _ from 'lodash'
import React from 'react'
import { Segment, Table } from 'semantic-ui-react'
import "../styles/components/SelectCard.scss"
import { observer } from "mobx-react"
import store from '../actions/CreateCardStore.js';

const data = [
    {
        id: 1,
        dimensions: "210mm x 290mm",
        price: "£19.95",
    },
    {
        id: 2,
        dimensions: "300mm x 450mm",
        price: "£29.95",
    },
    {
        id: 3,
        dimensions: "500mm x 700mm",
        price: "£39.95",
    }
]



const SelectCardSize = observer(() => {
    var newArr = _.orderBy(data, 'price', 'asc')

    const setSelection = (size) => {
        store.cardSizeId = size.id;
    }

    const cardSizes = (
        newArr.map((_size, index) =>
            <Table.Row key={index}
                className={`select-card-row ${store.cardSizeId === _size.id ? 'selected' : ''}`}
                onClick={() => setSelection(_size)}>
                <Table.Cell>{_size.dimensions}</Table.Cell>
                <Table.Cell>{_size.price}</Table.Cell>
            </Table.Row>
        )
    );

    return (
        <Segment vertical>
            <Table className='select-card-table' unstackable striped celled textAlign="left">
                <Table.Body>
                    {cardSizes}
                </Table.Body>

            </Table>
        </Segment>
    )
})

export default SelectCardSize