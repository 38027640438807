import React from 'react'
import { Grid, Header, Segment, List, Icon } from 'semantic-ui-react'
import "../styles/components/HeaderFooter.scss";


const PageFooter = () => (
  <Segment className='footer-segment'>
    <Grid className="footer-grid">
          <div className="list-container">
        <List link>
          <List.Item as='a'><Icon name='patreon' color='red'></Icon>Patreon</List.Item>
          <List.Item as='a'><Icon name='discord' color='grey'></Icon>Discord</List.Item>
          <List.Item as='a'><Icon name='instagram' color='red'></Icon>Discord</List.Item>
        </List>
      </div>

      <div className="list-container">
        <List link>
          <List.Item as='a'>Cookies Policy</List.Item>
          <List.Item as='a'>Privacy Policy</List.Item>
          <List.Item as='a'>Terms of Use</List.Item>
        </List>
      </div>

      <hr style={{ width: '100%' }}></hr>

      <Grid.Row>
        <Grid.Column textAlign='left'>
          <Header as="h5">© 2020 League Cards. All rights reserved.</Header>
        </Grid.Column>
      </Grid.Row>

    </Grid>
  </Segment>
)

export default PageFooter
