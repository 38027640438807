import React from 'react'
import { Image } from 'semantic-ui-react'

const CardContainer = (props) => {

    const { cardImage } = props
    return (
        <div className="actual-card">
            <div className="card-preview-wrapper">
                <Image className="card-preview" src={cardImage} ></Image>
            </div>
        </div>
    )
}

export default CardContainer