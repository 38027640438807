import React from 'react'
import "../styles/components/CustomiseCardDetails.scss"

import EditableImage from './EditableImage'

const CustomiseCardDetails = () => {
    return (
        <div className="customise-card-wrapper">
            <h2 className='image-editor-header'>Photo</h2>
            <EditableImage></EditableImage>
        </div>
    )
}

export default CustomiseCardDetails