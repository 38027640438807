import { observable, computed, decorate } from "mobx"

class CreateCardStore {
    /* Steps */
    currentStep = 1;

    get cardTypeStepCompleted() { return this.currentStep === 1 ? "in-progress" : this.currentStep > 1 ? 'completed' : 'none'; };
    get customizeCardStepCompleted() { return this.currentStep === 2 ? "in-progress" : this.currentStep > 2 ? 'completed' : 'none'; };
    get completeCardStepCompleted() { return this.currentStep === 3 ? "in-progress" : this.currentStep > 3 ? 'completed' : 'none'; };
    get completeOrderStepCompleted() { return this.currentStep === 4 ? "in-progress" : this.currentStep > 4 ? 'completed' : 'none'; };
    get checkoutStepCompleted() { return this.currentStep === 5 ? "in-progress" : this.currentStep > 5 ? 'completed' : 'none'; };

    get currentData() {
        return store;
    };

    get canEditCard() {
        return this.currentStep === 2 && this.editable;
    }
    
    clubList = [];
    filteredClubList = [];
    countryList = [];
    filteredCountryList = [];
    /* Card */
    selectedCard = undefined;
    availableCards = [];
    editable = false;

    /* Card Details */
    displayName = 'ANONIMOS';
    overallStat = 91;
    selectedCountry = 'cy';
    selectedClub = [];
    position = 'ST';

    statOneValue = 91;
    statOneDesc = 'PAC';
    statTwoValue = 92;
    statTwoDesc = 'SHO';
    statThreeValue = 93;
    statThreeDesc = 'PAS';
    statFourValue = 94;
    statFourDesc = 'DRI';
    statFiveValue = 95;
    statFiveDesc = 'DEF';
    statSixValue = 96;
    statSixDesc = 'PHY';

    uploadedPicture = null;
    croppedPicture = null;

    /* Order Details */
    cardSizeId = null;

}

decorate(CreateCardStore, {
    currentStep: observable,
    availableCards: observable,
    selectedCard: observable,
    editable: observable,
    displayName: observable,
    overallStat: observable,
    selectedCountry: observable,
    selectedClub: observable,
    position: observable,
    uploadedPicture: observable,
    croppedPicture: observable,
    clubList: observable,
    filteredClubList: observable,
    countryList: observable,
    filteredCountryList: observable,

    statOneValue: observable,
    statOneDesc: observable,
    statTwoValue: observable,
    statTwoDesc: observable,
    statThreeValue: observable,
    statThreeDesc: observable,
    statFourValue: observable,
    statFourDesc: observable,
    statFiveValue: observable,
    statFiveDesc: observable,
    statSixValue: observable,
    statSixDesc: observable,

    cardSizeId: observable,

    cardTypeStepCompleted: computed,
    customizeCardStepCompleted: computed,
    completeCardStepCompleted: computed,
    completeOrderStepCompleted: computed,
    checkoutStepCompleted: computed,
    currentData: computed,
    canEditCard: computed,

})

const store = new CreateCardStore();

export default store;