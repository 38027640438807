import React from 'react'
import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"
import "../styles/components/CreateCardSteps.scss"

const CreateCardSteps = observer(() => {
  return (
    <div className='card-steps'>
      <ul className='steps-list'>
        <li className={`list-item ${store.cardTypeStepCompleted}`}>Choose a Card Type</li>
        <li className={`list-item ${store.customizeCardStepCompleted}`}>Customize your Card</li>
        <li className={`list-item ${store.completeCardStepCompleted}`}>Complete Card Design</li>
        <li className={`list-item ${store.completeOrderStepCompleted}`}>Complete your order</li>
        <li className={`list-item ${store.checkoutStepCompleted}`}>Checkout</li>
      </ul>
    </div>
  )
})

export default CreateCardSteps
