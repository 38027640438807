import React, { useEffect } from "react";
import axios from 'axios'

const ConfirmEmailPage = () => {

    useEffect(() => {
        var token = new URLSearchParams(window.location.search).get('token');

        async function confirmEmail() {
            try {
                await axios.post(
                    `https://api.leaguecards.com/v1/mail/confirm`,
                    { token: token }
                )
                    .then((response) => {
                        return response.status
                    })
                    .catch((err) => console.warn(err));
            } catch (err) {
                console.error(err);
            }
        }

        confirmEmail()
    })

    return (
        <div></div>
    )
}


export default ConfirmEmailPage