import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import CreateCardPage from "../../pages/CreateCardPage";
import ConfirmEmailPage from "../../pages/ConfirmEmailPage";

import PublicLayoutRoute from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";

const Routes = () => {
    return (            
        <Switch>
            <PublicLayoutRoute path="/createcard" component={CreateCardPage} />

            <PrivateLayout path="/home" component={CreateCardPage} />

            <Route exact path="/">
                <Redirect to="/createcard" />
            </Route>

            <PublicLayoutRoute path="/auth/confirm" component={ConfirmEmailPage} />
            
            <Redirect to="/404" />
            <CreateCardPage render={() => <Redirect to="/404" />} />
        </Switch>
    );
}
export default Routes


