import React from 'react'
import { Button, Icon, Popup } from 'semantic-ui-react'
import FutPlaceholder from "../assets/gold1.png"
import TransparentCard from "../assets/landing/step-1-card-outline.svg"
import '../styles/components/CustomiseCard.scss'
import CardOverlay from './CardOverlay'
import store from '../actions/CreateCardStore.js';
import CardContainer from '../components/CardContainer'
import { observer } from "mobx-react"
import EditableCardOverlay from '../containers/EditableCardOverlay'

const CustomiseCard = observer((props) => {
    const { selectedCard, hasOverlay } = props;

    const editCard = () => {
        store.editable = !store.editable;
    }

    return (
        <div className='card-wrapper'>

            {store.currentStep === 2 ?
                <div>
                    <Popup className='edit-popup' content='Toggle a view with or without the card to focus on the details or view the overall product' trigger={<Icon name='question circle outline'></Icon>} />

                    {store.editable ?
                        <Icon className="edit-button" onClick={() => editCard()} name='check square outline' color='green'></Icon>
                        :
                        <Icon name='square outline' onClick={() => editCard()}></Icon>
                    }
                    <span>hide card</span>

                </div>
                :
                <></>
            }

            {store.canEditCard ?
                <>
                    <EditableCardOverlay></EditableCardOverlay>
                    <CardContainer cardImage={TransparentCard}></CardContainer>
                </>
                :
                <>
                    {hasOverlay ?
                        <CardOverlay textColor={selectedCard !== undefined ? selectedCard.textColor : 'white'}></CardOverlay>
                        :
                        <></>
                    }
                    {selectedCard !== undefined ?
                        <CardContainer cardImage={selectedCard.cardImage}></CardContainer>
                        :
                        <CardContainer cardImage={FutPlaceholder}></CardContainer>
                    }
                </>
            }


        </div>
    )
})

export default CustomiseCard
