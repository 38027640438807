import _ from 'lodash'
import React, { useEffect } from 'react'
import { Table, Image, Icon, Popup, Button } from 'semantic-ui-react'
import "../styles/components/SelectClub.scss"
import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"
import SearchBar from "../components/SearchBar"


const SelectClub = observer(() => {
    useEffect(() => {
        //init data
        //resets the list when coming back to this screen
        store.filteredClubList = store.clubList;
    }, []);


    var newArr = _.orderBy(store.filteredClubList, 'clubName', 'desc')

    const setSelection = (club) => {
        store.selectedClub = club
    }

    const cardSelection = (
        newArr.map((_club, index) =>
            <Table.Row key={index}
                className={`select-club-row ${store.selectedClub.clubId === _club.clubId ? 'selected' : ''}`}
                onClick={() => setSelection(_club)}>

                <Table.Cell colSpan='1' className='club-icon-column'><Image className='club-icon' src={_club.crest}></Image></Table.Cell>
                <Table.Cell colSpan='2'>{_club.clubName}</Table.Cell>
                <Table.Cell colSpan='1'>{_club.league}</Table.Cell>
            </Table.Row>
        )
    );

    const setResults = (results) => {
        store.filteredClubList = results;
    }

    return (
        <div className='select-club-tool'>
            <Table className='select-club-table' unstackable striped celled textAlign="left">
                <Table.Header className="select-club-table-header">
                    <Table.Row>
                        <Table.HeaderCell colSpan='3'>Select Team</Table.HeaderCell>
                        <Popup className='upload-popup' position='top right' wide
                            trigger={<Table.HeaderCell colSpan='1'><Icon name='question'></Icon></Table.HeaderCell>} on='click'>
                            <h4>Looking for something else?</h4>
                            <Button className='upload-photo'>upload photo</Button>
                        </Popup>
                    </Table.Row>
                    <Table.Row className='search-row'>
                        <Table.HeaderCell colSpan='4'>
                            <SearchBar dataToSearch={store.clubList} setResults={setResults}></SearchBar>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {cardSelection}
                </Table.Body>

            </Table>
        </div>
    )
})

export default SelectClub