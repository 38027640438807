import React, { useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import '../styles/pages/CreateCardPage.scss';
import CreateCardSteps from "../components/CreateCardSteps"
import SelectCard from "../containers/SelectCard"
import CustomiseCard from '../containers/CustomiseCard';
import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"
import SelectCardSize from '../containers/SelectCardSize';
import CustomiseCardDetails from '../components/CustomiseCardDetails';
import CompleteCardDesign from '../components/CompleteCardDesign';


import GoldCardIcon from "../assets/gold1.png"
import Gold2CardIcon from "../assets/gold2.png"
import Gold3CardIcon from "../assets/gold3.png"
import ConfirmOrder from '../components/ConfirmOrder';

/* Below will be replaced with API. Do not manipulate. */
const data = [
    {
        cardId: 1,
        cardImage: GoldCardIcon,
        cardName: "Something amazing",
        price: "£19.95",
        popular: true,
        textColor: '#000000'
    },
    {
        cardId: 2,
        cardImage: Gold2CardIcon,
        cardName: "The best",
        price: "£22.95",
        popular: true,
        textColor: '#cce2ff'
    },
    {
        cardId: 3,
        cardImage: Gold3CardIcon,
        cardName: "Something even more amazing",
        price: "£29.95",
        popular: false,
        textColor: '#fff'
    },
    {
        cardId: 4,
        cardImage: GoldCardIcon,
        cardName: "And a few more",
        price: "£29.95",
        popular: false,
        textColor: '#000000'
    },
    {
        cardId: 5,
        cardImage: GoldCardIcon,
        cardName: "As well as this",
        price: "£29.95",
        popular: false,
        textColor: '#000000'
    },
    {
        cardId: 6,
        cardImage: GoldCardIcon,
        cardName: "Is this the last?",
        price: "£29.95",
        popular: false,
        textColor: '#000000'
    },
    {
        cardId: 7,
        cardImage: GoldCardIcon,
        cardName: "No.",
        price: "£29.95",
        popular: false,
        textColor: '#000000'
    },
]
const clubData = [
    {
        clubId: 1,
        clubName: "Real Madrid",
        league: "La Liga",
        crest: "",
        title: "Real Madrid La Liga" //search thingy
    },
    {
        clubId: 2,
        clubName: "FC Barcelona",
        league: "La Liga",
        crest: "",
        title: "FC Barcelona La Liga" //search thingy
    },
    {
        clubId: 3,
        clubName: "Bayern Munich",
        league: "Bundesliga",
        crest: "",
        title: "Bayern Munich Bundesliga" //search thingy
    },
]



const CreateCardPage = observer(() => {
    const setSelection = (card) => {
        store.selectedCard = card
    }
    const goToNextStep = () => {
        if ((store.currentStep + 1) === 2){
            store.editable = true;
        }
        else{
            store.editable = false;
        }
        store.currentStep++;
    }

    const goToPrevStep = () => {
        if ((store.currentStep - 1) === 2){
            store.editable = true;
        }
        else{
            store.editable = false;
        }
        store.currentStep--;
    }

    const confirmOrder = () => {
        console.log('confirm');
    }

    useEffect(() => {
        //init data
        store.availableCards = data;
        store.clubList = clubData;
        store.countryList = countryData;
        store.filteredCountryList = store.countryList;
        store.filteredClubList = store.clubList;
        store.selectedCard = store.availableCards[0]
    }, []);

    return (
        <>
            <main className="main-content create-card">
                <CreateCardSteps></CreateCardSteps>

                {(() => {
                    switch (store.currentStep) {
                        case 1:
                            return <CustomiseCard selectedCard={store.selectedCard} hasOverlay={false}></CustomiseCard>
                        case 2:
                        case 3:
                        case 4:
                            return <CustomiseCard selectedCard={store.selectedCard} hasOverlay={true}></CustomiseCard>
                        case 5:
                            return null;
                        default:
                            return null;
                    }
                })()}

                <div className='card-tools'>
                    {(() => {
                        switch (store.currentStep) {
                            case 1:
                                return <SelectCard setSelection={setSelection}></SelectCard>;
                            case 2:
                                return <CompleteCardDesign></CompleteCardDesign>
                            case 3:
                                return <CustomiseCardDetails></CustomiseCardDetails>;
                            case 4:
                                return <SelectCardSize></SelectCardSize>;
                            case 5:
                                return <ConfirmOrder></ConfirmOrder>;
                            default:
                                return null;
                        }
                    })()}
                </div>

                <div className='button-row'>

                    <div className='button-wrapper'>
                        {(() => {
                            switch (store.currentStep) {
                                case 1:
                                    return <>
                                        <Button className="cancel-button" disabled>Step {store.currentStep - 1}</Button>
                                        <Button className="submit-button" onClick={() => goToNextStep()}>Step {store.currentStep + 1}</Button>
                                    </>;
                                case 2:
                                case 3:
                                case 4:
                                    return <>
                                        <Button className="cancel-button" onClick={() => goToPrevStep()}>Step {store.currentStep - 1}</Button>
                                        <Button className="submit-button" onClick={() => goToNextStep()}>Step {store.currentStep + 1}</Button>
                                    </>;
                                case 5:
                                    return <>
                                        <Button className="cancel-button" onClick={() => goToPrevStep()}>Step {store.currentStep - 1}</Button>
                                        <Button className="submit-button" onClick={() => confirmOrder()}>Finalise</Button>
                                    </>;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                </div>
            </main>

        </>

    )

})

export default CreateCardPage

const countryData = [
    { key: 'af', value: 'af', title: 'Afghanistan', flagIcon: 'af' },
    { key: 'ax', value: 'ax', title: 'Aland Islands', flagIcon: 'ax' },
    { key: 'al', value: 'al', title: 'Albania', flagIcon: 'al' },
    { key: 'dz', value: 'dz', title: 'Algeria', flagIcon: 'dz' },
    { key: 'as', value: 'as', title: 'American Samoa', flagIcon: 'as' },
    { key: 'ad', value: 'ad', title: 'Andorra', flagIcon: 'ad' },
    { key: 'ao', value: 'ao', title: 'Angola', flagIcon: 'ao' },
    { key: 'ai', value: 'ai', title: 'Anguilla', flagIcon: 'ai' },
    { key: 'ag', value: 'ag', title: 'Antigua', flagIcon: 'ag' },
    { key: 'ar', value: 'ar', title: 'Argentina', flagIcon: 'ar' },
    { key: 'am', value: 'am', title: 'Armenia', flagIcon: 'am' },
    { key: 'aw', value: 'aw', title: 'Aruba', flagIcon: 'aw' },
    { key: 'au', value: 'au', title: 'Australia', flagIcon: 'au' },
    { key: 'at', value: 'at', title: 'Austria', flagIcon: 'at' },
    { key: 'az', value: 'az', title: 'Azerbaijan', flagIcon: 'az' },
    { key: 'bs', value: 'bs', title: 'Bahamas', flagIcon: 'bs' },
    { key: 'bh', value: 'bh', title: 'Bahrain', flagIcon: 'bh' },
    { key: 'bd', value: 'bd', title: 'Bangladesh', flagIcon: 'bd' },
    { key: 'bb', value: 'bb', title: 'Barbados', flagIcon: 'bb' },
    { key: 'by', value: 'by', title: 'Belarus', flagIcon: 'by' },
    { key: 'be', value: 'be', title: 'Belgium', flagIcon: 'be' },
    { key: 'bz', value: 'bz', title: 'Belize', flagIcon: 'bz' },
    { key: 'bj', value: 'bj', title: 'Benin', flagIcon: 'bj' },
    { key: 'bm', value: 'bm', title: 'Bermuda', flagIcon: 'bm' },
    { key: 'bt', value: 'bt', title: 'Bhutan', flagIcon: 'bt' },
    { key: 'bo', value: 'bo', title: 'Bolivia', flagIcon: 'bo' },
    { key: 'ba', value: 'ba', title: 'Bosnia', flagIcon: 'ba' },
    { key: 'bw', value: 'bw', title: 'Botswana', flagIcon: 'bw' },
    { key: 'bv', value: 'bv', title: 'Bouvet Island', flagIcon: 'bv' },
    { key: 'br', value: 'br', title: 'Brazil', flagIcon: 'br' },
    { key: 'vg', value: 'vg', title: 'British Virgin Islands', flagIcon: 'vg' },
    { key: 'bn', value: 'bn', title: 'Brunei', flagIcon: 'bn' },
    { key: 'bg', value: 'bg', title: 'Bulgaria', flagIcon: 'bg' },
    { key: 'bf', value: 'bf', title: 'Burkina Faso', flagIcon: 'bf' },
    { key: 'mm', value: 'mm', title: 'Burma', flagIcon: 'mm', alias: 'Myanmar' },
    { key: 'bi', value: 'bi', title: 'Burundi', flagIcon: 'bi' },
    { key: 'tc', value: 'tc', title: 'Caicos Islands', flagIcon: 'tc' },
    { key: 'kh', value: 'kh', title: 'Cambodia', flagIcon: 'kh' },
    { key: 'cm', value: 'cm', title: 'Cameroon', flagIcon: 'cm' },
    { key: 'ca', value: 'ca', title: 'Canada', flagIcon: 'ca' },
    { key: 'cv', value: 'cv', title: 'Cape Verde', flagIcon: 'cv' },
    { key: 'ky', value: 'ky', title: 'Cayman Islands', flagIcon: 'ky' },
    { key: 'cf', value: 'cf', title: 'Central African Republic', flagIcon: 'cf' },
    { key: 'td', value: 'td', title: 'Chad', flagIcon: 'td' },
    { key: 'cl', value: 'cl', title: 'Chile', flagIcon: 'cl' },
    { key: 'cn', value: 'cn', title: 'China', flagIcon: 'cn' },
    { key: 'cx', value: 'cx', title: 'Christmas Island', flagIcon: 'cx' },
    { key: 'cc', value: 'cc', title: 'Cocos Islands', flagIcon: 'cc' },
    { key: 'co', value: 'co', title: 'Colombia', flagIcon: 'co' },
    { key: 'km', value: 'km', title: 'Comoros', flagIcon: 'km' },
    { key: 'cd', value: 'cd', title: 'Congo', flagIcon: 'cd' },
    { key: 'cg', value: 'cg', title: 'Congo Brazzaville', flagIcon: 'cg' },
    { key: 'ck', value: 'ck', title: 'Cook Islands', flagIcon: 'ck' },
    { key: 'cr', value: 'cr', title: 'Costa Rica', flagIcon: 'cr' },
    { key: 'ci', value: 'ci', title: 'Cote Divoire', flagIcon: 'ci' },
    { key: 'hr', value: 'hr', title: 'Croatia', flagIcon: 'hr' },
    { key: 'cu', value: 'cu', title: 'Cuba', flagIcon: 'cu' },
    { key: 'cy', value: 'cy', title: 'Cyprus', flagIcon: 'cy' },
    { key: 'cz', value: 'cz', title: 'Czech Republic', flagIcon: 'cz' },
    { key: 'dk', value: 'dk', title: 'Denmark', flagIcon: 'dk' },
    { key: 'dj', value: 'dj', title: 'Djibouti', flagIcon: 'dj' },
    { key: 'dm', value: 'dm', title: 'Dominica', flagIcon: 'dm' },
    { key: 'do', value: 'do', title: 'Dominican Republic', flagIcon: 'do' },
    { key: 'ec', value: 'ec', title: 'Ecuador', flagIcon: 'ec' },
    { key: 'eg', value: 'eg', title: 'Egypt', flagIcon: 'eg' },
    { key: 'sv', value: 'sv', title: 'El Salvador', flagIcon: 'sv' },
    { key: 'gq', value: 'gq', title: 'Equatorial Guinea', flagIcon: 'gq' },
    { key: 'er', value: 'er', title: 'Eritrea', flagIcon: 'er' },
    { key: 'ee', value: 'ee', title: 'Estonia', flagIcon: 'ee' },
    { key: 'et', value: 'et', title: 'Ethiopia', flagIcon: 'et' },
    { key: 'eu', value: 'eu', title: 'Europeanunion', flagIcon: 'eu' },
    { key: 'fk', value: 'fk', title: 'Falkland Islands', flagIcon: 'fk' },
    { key: 'fo', value: 'fo', title: 'Faroe Islands', flagIcon: 'fo' },
    { key: 'fj', value: 'fj', title: 'Fiji', flagIcon: 'fj' },
    { key: 'fi', value: 'fi', title: 'Finland', flagIcon: 'fi' },
    { key: 'fr', value: 'fr', title: 'France', flagIcon: 'fr' },
    { key: 'gf', value: 'gf', title: 'French Guiana', flagIcon: 'gf' },
    { key: 'pf', value: 'pf', title: 'French Polynesia', flagIcon: 'pf' },
    { key: 'tf', value: 'tf', title: 'French Territories', flagIcon: 'tf' },
    { key: 'ga', value: 'ga', title: 'Gabon', flagIcon: 'ga' },
    { key: 'gm', value: 'gm', title: 'Gambia', flagIcon: 'gm' },
    { key: 'ge', value: 'ge', title: 'Georgia', flagIcon: 'ge' },
    { key: 'de', value: 'de', title: 'Germany', flagIcon: 'de' },
    { key: 'gh', value: 'gh', title: 'Ghana', flagIcon: 'gh' },
    { key: 'gi', value: 'gi', title: 'Gibraltar', flagIcon: 'gi' },
    { key: 'gr', value: 'gr', title: 'Greece', flagIcon: 'gr' },
    { key: 'gl', value: 'gl', title: 'Greenland', flagIcon: 'gl' },
    { key: 'gd', value: 'gd', title: 'Grenada', flagIcon: 'gd' },
    { key: 'gp', value: 'gp', title: 'Guadeloupe', flagIcon: 'gp' },
    { key: 'gu', value: 'gu', title: 'Guam', flagIcon: 'gu' },
    { key: 'gt', value: 'gt', title: 'Guatemala', flagIcon: 'gt' },
    { key: 'gn', value: 'gn', title: 'Guinea', flagIcon: 'gn' },
    { key: 'gw', value: 'gw', title: 'Guinea-Bissau', flagIcon: 'gw' },
    { key: 'gy', value: 'gy', title: 'Guyana', flagIcon: 'gy' },
    { key: 'ht', value: 'ht', title: 'Haiti', flagIcon: 'ht' },
    { key: 'hm', value: 'hm', title: 'Heard Island', flagIcon: 'hm' },
    { key: 'hn', value: 'hn', title: 'Honduras', flagIcon: 'hn' },
    { key: 'hk', value: 'hk', title: 'Hong Kong', flagIcon: 'hk' },
    { key: 'hu', value: 'hu', title: 'Hungary', flagIcon: 'hu' },
    { key: 'is', value: 'is', title: 'Iceland', flagIcon: 'is' },
    { key: 'in', value: 'in', title: 'India', flagIcon: 'in' },
    { key: 'io', value: 'io', title: 'Indian Ocean Territory', flagIcon: 'io' },
    { key: 'id', value: 'id', title: 'Indonesia', flagIcon: 'id' },
    { key: 'ir', value: 'ir', title: 'Iran', flagIcon: 'ir' },
    { key: 'iq', value: 'iq', title: 'Iraq', flagIcon: 'iq' },
    { key: 'ie', value: 'ie', title: 'Ireland', flagIcon: 'ie' },
    { key: 'il', value: 'il', title: 'Israel', flagIcon: 'il' },
    { key: 'it', value: 'it', title: 'Italy', flagIcon: 'it' },
    { key: 'jm', value: 'jm', title: 'Jamaica', flagIcon: 'jm' },
    { key: 'sj', value: 'sj', title: 'Jan Mayen', flagIcon: 'sj', alias: 'Svalbard' },
    { key: 'jp', value: 'jp', title: 'Japan', flagIcon: 'jp' },
    { key: 'jo', value: 'jo', title: 'Jordan', flagIcon: 'jo' },
    { key: 'kz', value: 'kz', title: 'Kazakhstan', flagIcon: 'kz' },
    { key: 'ke', value: 'ke', title: 'Kenya', flagIcon: 'ke' },
    { key: 'ki', value: 'ki', title: 'Kiribati', flagIcon: 'ki' },
    { key: 'kw', value: 'kw', title: 'Kuwait', flagIcon: 'kw' },
    { key: 'kg', value: 'kg', title: 'Kyrgyzstan', flagIcon: 'kg' },
    { key: 'la', value: 'la', title: 'Laos', flagIcon: 'la' },
    { key: 'lv', value: 'lv', title: 'Latvia', flagIcon: 'lv' },
    { key: 'lb', value: 'lb', title: 'Lebanon', flagIcon: 'lb' },
    { key: 'ls', value: 'ls', title: 'Lesotho', flagIcon: 'ls' },
    { key: 'lr', value: 'lr', title: 'Liberia', flagIcon: 'lr' },
    { key: 'ly', value: 'ly', title: 'Libya', flagIcon: 'ly' },
    { key: 'li', value: 'li', title: 'Liechtenstein', flagIcon: 'li' },
    { key: 'lt', value: 'lt', title: 'Lithuania', flagIcon: 'lt' },
    { key: 'lu', value: 'lu', title: 'Luxembourg', flagIcon: 'lu' },
    { key: 'mo', value: 'mo', title: 'Macau', flagIcon: 'mo' },
    { key: 'mk', value: 'mk', title: 'Macedonia', flagIcon: 'mk' },
    { key: 'mg', value: 'mg', title: 'Madagascar', flagIcon: 'mg' },
    { key: 'mw', value: 'mw', title: 'Malawi', flagIcon: 'mw' },
    { key: 'my', value: 'my', title: 'Malaysia', flagIcon: 'my' },
    { key: 'mv', value: 'mv', title: 'Maldives', flagIcon: 'mv' },
    { key: 'ml', value: 'ml', title: 'Mali', flagIcon: 'ml' },
    { key: 'mt', value: 'mt', title: 'Malta', flagIcon: 'mt' },
    { key: 'mh', value: 'mh', title: 'Marshall Islands', flagIcon: 'mh' },
    { key: 'mq', value: 'mq', title: 'Martinique', flagIcon: 'mq' },
    { key: 'mr', value: 'mr', title: 'Mauritania', flagIcon: 'mr' },
    { key: 'mu', value: 'mu', title: 'Mauritius', flagIcon: 'mu' },
    { key: 'yt', value: 'yt', title: 'Mayotte', flagIcon: 'yt' },
    { key: 'mx', value: 'mx', title: 'Mexico', flagIcon: 'mx' },
    { key: 'fm', value: 'fm', title: 'Micronesia', flagIcon: 'fm' },
    { key: 'md', value: 'md', title: 'Moldova', flagIcon: 'md' },
    { key: 'mc', value: 'mc', title: 'Monaco', flagIcon: 'mc' },
    { key: 'mn', value: 'mn', title: 'Mongolia', flagIcon: 'mn' },
    { key: 'me', value: 'me', title: 'Montenegro', flagIcon: 'me' },
    { key: 'ms', value: 'ms', title: 'Montserrat', flagIcon: 'ms' },
    { key: 'ma', value: 'ma', title: 'Morocco', flagIcon: 'ma' },
    { key: 'mz', value: 'mz', title: 'Mozambique', flagIcon: 'mz' },
    { key: 'na', value: 'na', title: 'Namibia', flagIcon: 'na' },
    { key: 'nr', value: 'nr', title: 'Nauru', flagIcon: 'nr' },
    { key: 'np', value: 'np', title: 'Nepal', flagIcon: 'np' },
    { key: 'nl', value: 'nl', title: 'Netherlands', flagIcon: 'nl' },
    { key: 'an', value: 'an', title: 'Netherlandsantilles', flagIcon: 'an' },
    { key: 'nc', value: 'nc', title: 'New Caledonia', flagIcon: 'nc' },
    { key: 'pg', value: 'pg', title: 'New Guinea', flagIcon: 'pg' },
    { key: 'nz', value: 'nz', title: 'New Zealand', flagIcon: 'nz' },
    { key: 'ni', value: 'ni', title: 'Nicaragua', flagIcon: 'ni' },
    { key: 'ne', value: 'ne', title: 'Niger', flagIcon: 'ne' },
    { key: 'ng', value: 'ng', title: 'Nigeria', flagIcon: 'ng' },
    { key: 'nu', value: 'nu', title: 'Niue', flagIcon: 'nu' },
    { key: 'nf', value: 'nf', title: 'Norfolk Island', flagIcon: 'nf' },
    { key: 'kp', value: 'kp', title: 'North Korea', flagIcon: 'kp' },
    { key: 'mp', value: 'mp', title: 'Northern Mariana Islands', flagIcon: 'mp' },
    { key: 'no', value: 'no', title: 'Norway', flagIcon: 'no' },
    { key: 'om', value: 'om', title: 'Oman', flagIcon: 'om' },
    { key: 'pk', value: 'pk', title: 'Pakistan', flagIcon: 'pk' },
    { key: 'pw', value: 'pw', title: 'Palau', flagIcon: 'pw' },
    { key: 'ps', value: 'ps', title: 'Palestine', flagIcon: 'ps' },
    { key: 'pa', value: 'pa', title: 'Panama', flagIcon: 'pa' },
    { key: 'py', value: 'py', title: 'Paraguay', flagIcon: 'py' },
    { key: 'pe', value: 'pe', title: 'Peru', flagIcon: 'pe' },
    { key: 'ph', value: 'ph', title: 'Philippines', flagIcon: 'ph' },
    { key: 'pn', value: 'pn', title: 'Pitcairn Islands', flagIcon: 'pn' },
    { key: 'pl', value: 'pl', title: 'Poland', flagIcon: 'pl' },
    { key: 'pt', value: 'pt', title: 'Portugal', flagIcon: 'pt' },
    { key: 'pr', value: 'pr', title: 'Puerto Rico', flagIcon: 'pr' },
    { key: 'qa', value: 'qa', title: 'Qatar', flagIcon: 'qa' },
    { key: 're', value: 're', title: 'Reunion', flagIcon: 're' },
    { key: 'ro', value: 'ro', title: 'Romania', flagIcon: 'ro' },
    { key: 'ru', value: 'ru', title: 'Russia', flagIcon: 'ru' },
    { key: 'rw', value: 'rw', title: 'Rwanda', flagIcon: 'rw' },
    { key: 'sh', value: 'sh', title: 'Saint Helena', flagIcon: 'sh' },
    { key: 'kn', value: 'kn', title: 'Saint Kitts and Nevis', flagIcon: 'kn' },
    { key: 'lc', value: 'lc', title: 'Saint Lucia', flagIcon: 'lc' },
    { key: 'pm', value: 'pm', title: 'Saint Pierre', flagIcon: 'pm' },
    { key: 'vc', value: 'vc', title: 'Saint Vincent', flagIcon: 'vc' },
    { key: 'ws', value: 'ws', title: 'Samoa', flagIcon: 'ws' },
    { key: 'sm', value: 'sm', title: 'San Marino', flagIcon: 'sm' },
    { key: 'gs', value: 'gs', title: 'Sandwich Islands', flagIcon: 'gs' },
    { key: 'st', value: 'st', title: 'Sao Tome', flagIcon: 'st' },
    { key: 'sa', value: 'sa', title: 'Saudi Arabia', flagIcon: 'sa' },
    { key: 'gb sct', value: 'gb sct', title: 'Scotland', flagIcon: 'gb sct' },
    { key: 'sn', value: 'sn', title: 'Senegal', flagIcon: 'sn' },
    { key: 'cs', value: 'cs', title: 'Serbia', flagIcon: 'cs' },
    { key: 'rs', value: 'rs', title: 'Serbia', flagIcon: 'rs' },
    { key: 'sc', value: 'sc', title: 'Seychelles', flagIcon: 'sc' },
    { key: 'sl', value: 'sl', title: 'Sierra Leone', flagIcon: 'sl' },
    { key: 'sg', value: 'sg', title: 'Singapore', flagIcon: 'sg' },
    { key: 'sk', value: 'sk', title: 'Slovakia', flagIcon: 'sk' },
    { key: 'si', value: 'si', title: 'Slovenia', flagIcon: 'si' },
    { key: 'sb', value: 'sb', title: 'Solomon Islands', flagIcon: 'sb' },
    { key: 'so', value: 'so', title: 'Somalia', flagIcon: 'so' },
    { key: 'za', value: 'za', title: 'South Africa', flagIcon: 'za' },
    { key: 'kr', value: 'kr', title: 'South Korea', flagIcon: 'kr' },
    { key: 'es', value: 'es', title: 'Spain', flagIcon: 'es' },
    { key: 'lk', value: 'lk', title: 'Sri Lanka', flagIcon: 'lk' },
    { key: 'sd', value: 'sd', title: 'Sudan', flagIcon: 'sd' },
    { key: 'sr', value: 'sr', title: 'Suritext', flagIcon: 'sr' },
    { key: 'sz', value: 'sz', title: 'Swaziland', flagIcon: 'sz' },
    { key: 'se', value: 'se', title: 'Sweden', flagIcon: 'se' },
    { key: 'ch', value: 'ch', title: 'Switzerland', flagIcon: 'ch' },
    { key: 'sy', value: 'sy', title: 'Syria', flagIcon: 'sy' },
    { key: 'tw', value: 'tw', title: 'Taiwan', flagIcon: 'tw' },
    { key: 'tj', value: 'tj', title: 'Tajikistan', flagIcon: 'tj' },
    { key: 'tz', value: 'tz', title: 'Tanzania', flagIcon: 'tz' },
    { key: 'th', value: 'th', title: 'Thailand', flagIcon: 'th' },
    { key: 'tl', value: 'tl', title: 'Timorleste', flagIcon: 'tl' },
    { key: 'tg', value: 'tg', title: 'Togo', flagIcon: 'tg' },
    { key: 'tk', value: 'tk', title: 'Tokelau', flagIcon: 'tk' },
    { key: 'to', value: 'to', title: 'Tonga', flagIcon: 'to' },
    { key: 'tt', value: 'tt', title: 'Trinidad', flagIcon: 'tt' },
    { key: 'tn', value: 'tn', title: 'Tunisia', flagIcon: 'tn' },
    { key: 'tr', value: 'tr', title: 'Turkey', flagIcon: 'tr' },
    { key: 'tm', value: 'tm', title: 'Turkmenistan', flagIcon: 'tm' },
    { key: 'tv', value: 'tv', title: 'Tuvalu', flagIcon: 'tv' },
    { key: 'ae', value: 'ae', title: 'U.A.E.', flagIcon: 'ae', alias: 'United Arab Emirates' },
    { key: 'ug', value: 'ug', title: 'Uganda', flagIcon: 'ug' },
    { key: 'ua', value: 'ua', title: 'Ukraine', flagIcon: 'ua' },
    { key: 'gb', value: 'gb', title: 'United Kingdom', flagIcon: 'gb', alias: 'uk' },
    { key: 'us', value: 'us', title: 'United States', flagIcon: 'us', alias: 'America' },
    { key: 'uy', value: 'uy', title: 'Uruguay', flagIcon: 'uy' },
    { key: 'um', value: 'um', title: 'US Minor Islands', flagIcon: 'um' },
    { key: 'vi', value: 'vi', title: 'US Virgin Islands', flagIcon: 'vi' },
    { key: 'uz', value: 'uz', title: 'Uzbekistan', flagIcon: 'uz' },
    { key: 'vu', value: 'vu', title: 'Vanuatu', flagIcon: 'vu' },
    { key: 'va', value: 'va', title: 'Vatican City', flagIcon: 'va' },
    { key: 've', value: 've', title: 'Venezuela', flagIcon: 've' },
    { key: 'vn', value: 'vn', title: 'Vietnam', flagIcon: 'vn' },
    { key: 'gb wls', value: 'gb wls', title: 'Wales', flagIcon: 'gb wls' },
    { key: 'wf', value: 'wf', title: 'Wallis and Futuna', flagIcon: 'wf' },
    { key: 'eh', value: 'eh', title: 'Western Sahara', flagIcon: 'eh' },
    { key: 'ye', value: 'ye', title: 'Yemen', flagIcon: 'ye' },
    { key: 'zm', value: 'zm', title: 'Zambia', flagIcon: 'zm' },
    { key: 'zw', value: 'zw', title: 'Zimbabwe', flagIcon: 'zw' },
]