
import React, { useState } from "react";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../styles/components/ConfirmOrder.scss"

const ConfirmOrder = () => {
    const stripe = loadStripe(
        "pk_test_WIkfCMMBU2S6QUsien00drFz00Qa0tRVW0"
    );
    return (
        <Elements stripe={stripe}>
            <CheckoutForm />
        </Elements>
    );
};

export default ConfirmOrder
const getClientSecret = () => {
    return "pk_test_WIkfCMMBU2S6QUsien00drFz00Qa0tRVW0" //Test key
}

function CheckoutForm() {

    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const payMoney = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setPaymentLoading(true);
        // const clientSecret = getClientSecret();
        // const clientSecret  = await stripe.paymentRequest();
        
        // console.log(clientSecret);

        alert('You paid! (not really)')
        return
        const paymentResult = await stripe.confirmCardPayment(null, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: { name: "Mr. S",},
            },
        });

        setPaymentLoading(false);
        if (paymentResult.error) {
            alert(paymentResult.error.message);
        } else {
            if (paymentResult.paymentIntent.status === "succeeded") {
                alert("Success!");
            }
        }
    };

    return (
        <div>
            <span>TODO? - add finalised card preview here?</span>

            <div style={{ maxWidth: "500px", margin: "0 auto", }}>
                <form style={{ display: "block", width: "100%", }} onSubmit={payMoney}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    >
                        <CardElement
                            className="card"
                            options={{
                                style: {
                                    base: {
                                        backgroundColor: "white"
                                    }
                                },
                            }}
                        />
                        <button className="pay-button" disabled={isPaymentLoading}>
                            {isPaymentLoading ? "Loading..." : "Pay"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
