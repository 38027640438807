import React from 'react'
import { Image, Icon } from 'semantic-ui-react'
import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"
import Flag from 'react-world-flags'

const CardOverlay = observer((props) => {
    const { textColor } = props;

    const rotateCard = (goToNext) => {
        const maxCards = store.availableCards.length - 1;
        const currentCardIndex = store.availableCards.indexOf(store.selectedCard);
        if (goToNext) {
            if (currentCardIndex === maxCards)
                store.selectedCard = store.availableCards[0];
            else
                store.selectedCard = store.availableCards[currentCardIndex + 1];
        } else {
            if (currentCardIndex === 0)
                store.selectedCard = store.availableCards[maxCards];
            else
                store.selectedCard = store.availableCards[currentCardIndex - 1];
        }
    }


    return (
        <div className='card-overlay' style={{ color: textColor }}>
            <div className='card-stats-wrapper'>
                <div className='info-row' columns='2'>

                    <div className='left-column'>
                        <div className='overall-stat'>
                            <span>{store.overallStat}</span>
                        </div>
                        <div className='position-stat'>
                            <span>{store.position}</span>
                        </div>
                        <div className='country-stat'>
                            <Flag code={store.selectedCountry} height={32} />
                        </div>
                        <div className='club-stat'>
                            {/* <Flag name='france' /> */}
                        </div>
                    </div>

                    <div className='image-wrapper'>
                        <Image src={store.croppedPicture}></Image>
                    </div>
                </div>

                <div className='name-wrapper'>
                    <div className='name-field'>{store.displayName}</div>
                </div>

                <div className='stats-row'>
                    <div>{store.statOneValue} {store.statOneDesc}</div>
                    <div>{store.statTwoValue} {store.statTwoDesc}</div>
                    <div>{store.statThreeValue} {store.statThreeDesc}</div>
                    <div>{store.statFourValue} {store.statFourDesc}</div>
                    <div>{store.statFiveValue} {store.statFiveDesc}</div>
                    <div>{store.statSixValue} {store.statSixDesc}</div>
                </div>
            </div>
        </div>
    )
})

export default CardOverlay