import _ from 'lodash'
import React from 'react'
import { Responsive, Header, Table, Image } from 'semantic-ui-react'
import "../styles/components/SelectCard.scss"
import store from '../actions/CreateCardStore.js';
import { observer } from "mobx-react"


const SelectCard = observer((props) => {
    const { setSelection } = props;

    var newArr = _.orderBy(store.availableCards, 'price', 'desc')

    const cardSelection = (
        newArr.map((_card, index) =>
            <Table.Row key={index}
                className={`select-card-row ${store.selectedCard.cardId === _card.cardId ? 'selected' : ''}`}
                onClick={() => setSelection(_card)}>

                <Table.Cell><Image className='card-icon' src={_card.cardImage}></Image></Table.Cell>
                <Table.Cell>{_card.cardName}</Table.Cell>

                <Responsive as={Table.Cell} minWidth={1200}>{_card.price}</Responsive>
            </Table.Row>
        )
    );
    const popularCardsSelection = (
        newArr.filter(_card => _card.popular).map((_card, index) =>
            <Table.Row key={index} className="select-card-row" onClick={() => setSelection(_card)}>

                <Table.Cell><Image className='card-icon' src={_card.cardImage}></Image></Table.Cell>
                <Table.Cell>{_card.cardName}</Table.Cell>

                <Responsive as={Table.Cell} minWidth={1200}>{_card.price}</Responsive>
            </Table.Row>
        )
    );

    return (
        <div className='select-card-tool'>
            <Table className='select-card-table' unstackable striped celled textAlign="left">
                <Table.Header className="select-card-table-header">
                    <Table.Row>
                        <Table.HeaderCell>Preview</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Responsive as={Table.HeaderCell} minWidth={1200}>From</Responsive>
                    </Table.Row>
                </Table.Header>
                <Table.Row>
                </Table.Row>

                <Table.Body>

                    <Table.Cell className="sub-header" colSpan='3' textAlign="center">
                        <Header className="table-spacer-header" as='h3' color='teal'>Most Popular</Header>
                    </Table.Cell>
                    {popularCardsSelection}

                    <Table.Cell className="sub-header" colSpan='3'>
                        <hr style={{ width: '100%' }}></hr>
                    </Table.Cell>
                    {cardSelection}
                </Table.Body>

            </Table>
        </div>
    )
})

export default SelectCard