import React from 'react'
import store from '../actions/CreateCardStore.js';
import { Image, Flag } from 'semantic-ui-react'
import { observer } from "mobx-react"
import CardInputField from '../components/CardInputField'

import PlaceholderUpload from "../assets/placeholder-upload.png"

const EditableCardOverlay = observer(() => {
    // const [uploadedPicture, setUploadedPicture] = useState()
    /* LOOK INTO BELOW */
    const setStatOneValue = (newValue) => {
        store.statOneValue = newValue;
    }
    const setStatOneDesc = (newValue) => {
        store.statOneDesc = newValue.toUpperCase();
    }

    const setStatTwoValue = (newValue) => {
        store.statTwoValue = newValue;
    }
    const setStatTwoDesc = (newValue) => {
        store.statTwoDesc = newValue.toUpperCase();
    }

    const setStatThreeValue = (newValue) => {
        store.statThreeValue = newValue;
    }
    const setStatThreeDesc = (newValue) => {
        store.statThreeDesc = newValue.toUpperCase();
    }

    const setStatFourValue = (newValue) => {
        store.statFourValue = newValue;
    }
    const setStatFourDesc = (newValue) => {
        store.statFourDesc = newValue.toUpperCase();
    }

    const setStatFiveValue = (newValue) => {
        store.statFiveValue = newValue;
    }
    const setStatFiveDesc = (newValue) => {
        store.statFiveDesc = newValue.toUpperCase();
    }

    const setStatSixValue = (newValue) => {
        store.statSixValue = newValue;
    }
    const setStatSixDesc = (newValue) => {
        store.statSixDesc = newValue.toUpperCase();
    }

    /* Card data */
    const setDisplayName = (newValue) => {
        store.displayName = newValue.toUpperCase(); //to upper?
    }
    const setPosition = (newValue) => {
        store.position = newValue.toUpperCase(); //to upper?
    }
    const setOverall = (newValue) => {
        store.overallStat = newValue.toUpperCase(); //to upper?
    }

    return (
        <div className='card-overlay'>
            <div className='card-stats-wrapper'>
                <div className='info-row editable'>
                    <div className='left-column'>
                        <div className='overall-stat editable'>
                            <CardInputField placeholder='99' maxLength='2' type='text' pattern="[0-9]*" setInputValue={setOverall} ></CardInputField>
                        </div>
                        <div className='position-stat editable'>
                            <CardInputField placeholder='ST' maxLength='2' type='text' setInputValue={setPosition} ></CardInputField>
                        </div>
                        <div className='country-stat'>
                            <div className='stat-dropdown-wrapper'>
                                <Flag name={store.selectedCountry}></Flag>
                            </div>
                        </div>
                        <div className='club-stat'>
                            <div className='stat-dropdown-wrapper'>
                                <Flag name={store.selectedCountry}></Flag>
                            </div>
                        </div>
                    </div>

                    <div className='right-column'>
                        {store.currentStep === 2 ?
                            <Image src={PlaceholderUpload}></Image>
                            :
                            <Image src={store.croppedPicture}></Image>
                        }
                    </div>
                </div>

                <div className='name-wrapper'>
                    <CardInputField placeholder='YOUR NAME!' maxLength='2' type='text' pattern="[0-9]*" setInputValue={setDisplayName} ></CardInputField>
                </div>


                <div className='stats-row'>
                    <div className='stat-kv-pair'>
                        <CardInputField placeholder='99' maxLength='2' type='text' pattern="[0-9]*" setInputValue={setStatOneValue} ></CardInputField>
                        <CardInputField placeholder='PAC' maxLength='3' type='text' setInputValue={setStatOneDesc}></CardInputField>
                    </div>
                    <div className='stat-kv-pair'>
                        <CardInputField placeholder='99' maxLength='2' type='tel' pattern="[0-9]+" setInputValue={setStatTwoValue}></CardInputField>
                        <CardInputField placeholder='SHO' maxLength='3' type='text' setInputValue={setStatTwoDesc}></CardInputField>
                    </div>
                    <div className='stat-kv-pair'>
                        <CardInputField placeholder='99' maxLength='2' type='tel' pattern="[0-9]+" setInputValue={setStatThreeValue}></CardInputField>
                        <CardInputField placeholder='PAS' maxLength='3' type='text' setInputValue={setStatThreeDesc}></CardInputField>
                    </div>
                    <div className='stat-kv-pair'>
                        <CardInputField placeholder='99' maxLength='2' type='tel' pattern="[0-9]+" setInputValue={setStatFourValue}></CardInputField>
                        <CardInputField placeholder='DRI' maxLength='3' type='text' setInputValue={setStatFourDesc}></CardInputField>
                    </div>
                    <div className='stat-kv-pair'>
                        <CardInputField placeholder='99' maxLength='2' type='tel' pattern="[0-9]+" setInputValue={setStatFiveValue}></CardInputField>
                        <CardInputField placeholder='DEF' maxLength='3' type='text' setInputValue={setStatFiveDesc}></CardInputField>
                    </div>
                    <div className='stat-kv-pair'>
                        <CardInputField placeholder='99' maxLength='2' type='tel' pattern="[0-9]+" setInputValue={setStatSixValue}></CardInputField>
                        <CardInputField placeholder='PHY' maxLength='3' type='text' setInputValue={setStatSixDesc}></CardInputField>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default EditableCardOverlay