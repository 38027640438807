import React from 'react'
import "../styles/components/CardInputField.scss"

const CardInputField = (props) => {
    // const [inputValue, setInputValue] = useState();
    const { placeholder, maxLength, type, pattern, setInputValue } = props;

    return (
        <input className='card-input-field'
            placeholder={placeholder}
            maxLength={maxLength}
            type={type}
            pattern={pattern} 
            onChange={e => setInputValue(e.target.value)}
        />
    )
}

export default CardInputField
