import React from 'react';  
import { Route } from 'react-router-dom';  
  
import Layout from "./Layout";
import ScrollToTopBtn from "../../actions/ScrollToTop";
import PageFooter from "../PageFooter";

const MainLayout = ({ children }) => (

    <>
        <Layout children={children}></Layout>
        <ScrollToTopBtn />
        <PageFooter></PageFooter>
    </>
);

  const MainLayoutRoute = ({component: Component, ...rest}) => {  

    return (  
      <Route {...rest} render={matchProps => (  
        <MainLayout>  
            <Component {...matchProps} />  
        </MainLayout>  
      )} />  
    )  
  };  
  
export default MainLayoutRoute;